'use client';

import React from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { toClassName } from 'helpers/format';

import { TNextClientComponent } from 'types/next';

import { useScrollPosition } from 'hooks/useScrollPosition';

import { Button } from 'components/tailwind-ui/Button';
import Container from 'components/tailwind-ui/Container';

import { styles } from '.';

type TProps = {
  lang?: string;
};

const Navbar: TNextClientComponent<TProps> = () => {
  const scrollPosition = useScrollPosition();
  const pathname = usePathname();

  const routes = [
    {
      id: 'what-we-do',
      label: 'What we do',
      href: pathname.includes('/blog') ? '/#what-we-do' : '#what-we-do',
    },
    {
      id: 'who-we-are',
      label: 'Who we are',
      href: pathname.includes('/blog') ? '/#who-we-are' : '#who-we-are',
    },
    {
      id: 'what-we-offer',
      label: 'What we offer',
      href: pathname.includes('/blog') ? '/#what-we-offer' : '#what-we-offer',
    },
    {
      id: 'news',
      label: 'News',
      href: '/news',
    },
  ];

  return (
    <div
      className={toClassName(
        styles.container,
        scrollPosition > 1
          ? '!bg-[#1C1C1C] border-b border-[rgba(255,255,255,0.1)]'
          : 'bg-[#1C1C1C] sm:bg-transparent border-[#1C1C1C]',
        pathname.includes('/news') &&
          '!bg-[#1C1C1C] border-b border-[rgba(255,255,255,0.1)]',
      )}
    >
      <Container type="center">
        <div className={styles.content}>
          <div>
            <Link href="/#introduction">
              <Image
                src="/assets/images/logo-8.svg"
                width={130}
                height={50}
                alt="logo"
                className="w-20 lg:w-36"
              />
            </Link>
          </div>
          <ul className={styles.body}>
            {routes.map((route) => (
              <li key={route.id}>
                <Link
                  href={route.href}
                  className="opacity-60 hover:opacity-100 duration-200"
                >
                  {route.label}
                </Link>
              </li>
            ))}
          </ul>
          <div className="flex flex-row items-center gap-x-6">
            <Link href="/news" className="sm:hidden">
              News
            </Link>
            <a href="#get-in-contact">
              <Button>Contact us</Button>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
